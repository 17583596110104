
import React from "react";
import "./index.css";
import "./typing.css";



export default
class Presentation extends React.Component {
    original_label = "I will transform your project ideas into reality."
    
    constructor(props) {
        super(props);   

        this.state = {
            state : 0
        };        
    }

    componentDidMount() {
        /* Start the typing animation */
        this.interval = setInterval(() => {
            let temp_state = this.state.state+1;
            if (temp_state > this.original_label.length) { 
                clearInterval(this.interval); 
                return;
            }
            this.setState({state: temp_state});
        }, 30);

    }
    
    render() {
        return (
            <div className="text-center p-4 presentation ">
                <h1 className="display-4  fw-bold" >
                    <span className="cool-cyan"> &lt;</span> Max <span className="cool-blue">Ducoudré <span className="cool-cyan">/&gt;</span></span>
                </h1>
                <Nomination name="IT" title="engineer" />
                <Nomination name="Full-stack" title="developer" />
                <Nomination name="Software" title="developer" />
                <p className="lead it-vibe typed-text pt-4">
                    {this.original_label.substring(0, this.state.state)}
                </p>
            </div>
        );
    }
}

class Nomination extends React.Component {
    constructor(props) {
        super(props);   
    }
    render() {return (
        <h2 className="text-center mt-0 pt-0 pb-0">
            <span className="fst-italic underline-simple ">
                {this.props.name}
            </span>  
            {" " + this.props.title}
        </h2>
    );}
}