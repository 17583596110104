
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './typing.css';


export default
class Nav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            className: "navbar"
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        if (window.innerWidth < 800) { this.setState({className: "flex-column"});
        } else {this.setState({className: "navbar"});}
    }

    renderNavElement(navElement) {
        return (
            <NavElement 
                updateActiveElement={this.props.updateActiveElement}
                name={navElement.name} 
                number={navElement.num} 
                link={navElement.link} 
                active={this.props.activeElement === navElement.name}
            />
        );
    }
    render() {
        return (
            <nav className={this.state.className}>
                <div className="m-4 container-fluid">
                    <div className="nav-item pointer" onClick={() => this.props.removeActiveElement()}>
                        <h1>
                            <span className="cool-blue">MaxCode</span>
                            .
                            <span className="cool-purple">_</span>
                        </h1>
                    </div>
                    {this.props.navElements.map((e) => this.renderNavElement(e))}
                </div>
            </nav>
        ); 
    }
}

class NavElement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            alignment: "right-align",
        };        
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        if (window.innerWidth < 800) { this.setState({alignment: "d-none"});
        } else {this.setState({alignment: "text-end it-vibe mb-0"});}
    }

    render() {
        return (
            <div className="nav-item">
                <p className={this.state.alignment + " " + this.state.default}> 0{this.props.number}</p>
                <p onClick={() => this.props.updateActiveElement(this.props.name)}
                    className={"nav-link fs-5 it-vibe  text-lowercase pointer " + (this.props.active ? "active" : "underline")} href={this.props.link}>// {this.props.name}
                </p>
            </div>
        );
    }
}
