
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './typing.css';
import emailImage from './resources/e-mail.png'; 
import codeurImage from './resources/codeur.png'; 
import linkedinImage from './resources/linkedin.png'; 

export default
class Contacts extends React.Component {
    constructor(props) {
        super(props);   
    }
    render() {return (
        <div className="d-flex  justify-content-center">
            <Contact icon={emailImage} link="mailto:maxouducoudre@gmail.com" />
            <Contact icon={codeurImage} link="https://www.codeur.com/-kehwos" />
            <Contact icon={linkedinImage} link="https://www.linkedin.com/in/max-ducoudr%C3%A9-2b7789203/" />
        </div>
    );}
}



class Contact extends React.Component {
    constructor(props) {
        super(props);   
    }
    render() {return (
        <div className="p-2">
            <a href = {this.props.link} target="_blank">
                <img className="img-fluid icon" src={this.props.icon} />
            </a>
        </div>
    );}
}