import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './typing.css';

import Nav from './nav.js';
import Presentation from './presentation.js';
import Contacts from './contacts.js';

import nodeImg from './resources/node.png';
import reactImg from './resources/react.png';
import pythonImg from './resources/python.png';
import cppImg from './resources/cpp.png';
import cImg from './resources/c.png';
import javaImg from './resources/java.png';
import htmlImg from './resources/html.png';
import cssImg from './resources/css.png';
import jsImg from './resources/js.png';
import sqlImg from './resources/sql.png';
import linuxImg from './resources/linux.png';
import windowsImg from './resources/windows.png';
import tailwindImg from './resources/tailwind.png';
import androidImg from './resources/android.png';
import gitImg from './resources/git.png';
import dockerImg from './resources/docker.png';
import csImg from './resources/cs.png';

import bootstrapImg from './resources/bootstrap.png';
import helidonImg from './resources/helidon.png';
import springImg from './resources/spring.png';
import angularImg from './resources/angular.png';
import httpImg from './resources/http.png';
import wordpressImg from './resources/wordpress.png';
import jakartaImg from './resources/jakarta.png';
import laravelImg from './resources/laravel.png';


class App extends React.Component {
    lenguages = ["English", "Français"];
    constructor(props) {
        super(props);
        this.state = {
            activeElement: "NONE",
            navElements: [
                {name: "expertise", num:"1", link: "/expertise"},
                {name: "work",  num:"2", link: "/work"},
                {name: "experience", num:"3", link: "/experience"},
                {name: "contact", num:"4", link: "/contact"}
            ]
        };
    }
        
    render() {
        return (
            <div className="App">
                <Nav
                    activeElement={this.state.activeElement}
                    navElements={this.state.navElements} 
                    updateActiveElement={(e) => this.setState({activeElement: e})}
                    removeActiveElement={(e) => this.setState({activeElement: "NONE"})}
                />
                <Content activeElement={this.state.activeElement}/>
                <Presentation />
                <Contacts />
            </div>
        );
    }
}

class Content extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            imagesLoaded: false,
        };
        this.skills = 
        {
            "NodeJS":  nodeImg,
            "ReactJS": reactImg,
            "Python":  pythonImg,
            "C++":   cppImg,
            "C":   cImg,
            "C#":   csImg,
            "Java":  javaImg,
            "HTML": htmlImg,
            "CSS":  cssImg,
            "JavaScript":  jsImg,
            "SQL":  sqlImg,
            "Linux": linuxImg,
            "Windows":  windowsImg,
            "Tailwind": tailwindImg,
            "Android":  androidImg,
            "Git" : gitImg,
            "Docker" : dockerImg,
            "Bootstrap" : bootstrapImg,
            "Helidon" : helidonImg,
            "Spring" : springImg,
            "Angular" : angularImg,
            "HTTP" : httpImg,
            "Wordpress" : wordpressImg,
            "Jakarta" : jakartaImg,
            "Laravel" : laravelImg
        }
    }

    componentDidMount() {
        // Set a small timeout to trigger the appearance animation after the component mounts
        setTimeout(() => {
            this.setState({ visible: true });
        }, 100);
        this.loadImages();
    }

    loadImages() {
        const imgArray = Object.values(this.skills);
        imgArray.forEach((img) => {
            const image = new Image();
            image.src = img;
            image.onload = () => {
                // Image loaded successfully
                this.setState({ imagesLoaded: true });
            };
            image.onerror = () => {
                // Handle image loading error if needed
                console.error('Error loading image:', img);
            };
        });
    }
  
    render() {
        const skillKeys = Object.keys(this.skills); // Get the keys from the skills object
        const { visible } = this.state;
            
        if(this.props.activeElement === "expertise") {

            // return (
            //     <div className={`
            //         border p-1 ms-5 me-5 border-smooth content-part 
            //         ${visible ? 'fade-in' : ''}
            //         ${this.props.activeElement === "expertise" ? "" : "fade-out"}`}>
            //         <div className="scroll-container">
            //             <div className="skills-wrapper">
            //                 {skillKeys.map((key, index) => (
            //                     <Skill key={index} alt={key} icon={this.skills[key]} />
            //                 ))}
            //                 {/* Repeat the skills to create the infinite loop */}
            //                 {skillKeys.map((key, index) => (
            //                     <Skill key={index + skillKeys.length} alt={key} icon={this.skills[key]} />
            //                 ))}
            //             </div>
            //         </div>
            //     </div>
            //     );
        } 

        return (<div></div>);
        

    }
}

class Skill extends React.Component {
    render() {
        return (
            <img className="img-fluid skill-img m-1" alt={this.props.alt} src={this.props.icon} />
        );
    }
}



// ========================================
ReactDOM.createRoot(document.getElementById("root")).render(<App />);
